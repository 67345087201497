/**
 * These are declared twice like this because Tailwind can't handle dynamic classes
 * @see https://air-labs-team.slack.com/archives/C021YL20RH8/p1678852155120919?thread_ts=1678828856.949449&cid=C021YL20RH8
 */
export const TABLE_ROW_HEIGHT = 64;

export const TABLE_CELL_H_PADDING = 16;
export const TABLE_CELL_V_PADDING = 8;

export const DROPHIGHLIGHT_STYLES: React.CSSProperties = {
  top: -4,
  right: -20,
  left: -4,
  bottom: -4,
  zIndex: -1,
} as const;

export const SCROLL_CONTAINER_ID = 'scrollContainer';
export const getScrollContainer = (): HTMLDivElement | null => {
  if (typeof window === 'undefined') {
    return null;
  }

  return document.getElementById(SCROLL_CONTAINER_ID) as HTMLDivElement | null;
};
