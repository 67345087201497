import { CustomField, CustomFieldTypeName } from '@air/api/types';

import { AssignedCustomFieldValue } from '~/swr-hooks/customFields/types';

// TODO: these are magic numbers, we should find a better way to handle this
export const getAdditionalPopperOffset = (
  customField?: CustomField,
  assignedCustomFieldValue?: AssignedCustomFieldValue,
): number => {
  switch (customField?.type.title) {
    case CustomFieldTypeName.singleSelect:
    case CustomFieldTypeName.multiSelect: {
      return 4;
    }
    case CustomFieldTypeName.plainText: {
      return assignedCustomFieldValue?.plainTextValue ? 11 : 4;
    }
    case CustomFieldTypeName.date: {
      return assignedCustomFieldValue?.dateValue ? 11 : 4;
    }
    default: {
      return 0;
    }
  }
};
